import { TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-6h', to: 'now', display: 'Last 6h', section: 3 },
  { from: 'now-24h', to: 'now', display: 'Last 24h', section: 3 },
  { from: 'now-2d', to: 'now', display: 'Last 2d', section: 3 },
  { from: 'now-3d', to: 'now', display: 'Last 3d', section: 3 },
  { from: 'now-7d', to: 'now', display: 'Last 7d', section: 3 },
  { from: 'now-14d', to: 'now', display: 'Last 14d', section: 3 },
  { from: 'now-30d', to: 'now', display: 'Last 30d', section: 3 },
  { from: 'now-2h', to: 'now+6h', display: 'now-2h to now+6h', section: 3 },
  { from: 'now-12h', to: 'now+6h', display: 'now-12h to now+6h', section: 3 },
  { from: 'now-12h', to: 'now+48h', display: 'now-12h to now+48h', section: 3 },
  { from: 'now-12h', to: 'now+60h', display: 'now-12h to now+60h', section: 3 },
  { from: 'now-24h', to: 'now+12h', display: 'now-24h to now+12h', section: 3 },
  { from: 'now-36h', to: 'now+12h', display: 'now-36h to now+12h', section: 3 },
  { from: 'now-1d', to: 'now+2d', display: 'now-1d to now+2d', section: 3 },
  { from: 'now-1d', to: 'now+4d', display: 'now-1d to now+4d', section: 3 },
  { from: 'now-2d', to: 'now+7d', display: 'now-2d to now+7d', section: 3 },
  { from: 'now-7d', to: 'now+7d', display: 'now-7d to now+7d', section: 3 },
];
